import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { useMediaQuery } from 'react-responsive'
import { IG_LABELS, SP_LABELS, REGIONS_LIST } from '../utils/const'
// import { navigate } from 'gatsby-link'
import NavContext from './NavContext'
import { MediaQueries as Mq } from '../styles/MediaQueries'
import { IoMdClose } from 'react-icons/io'
import SectionNavMobile from './SectionNavMobile'

import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

const NavBar = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--white);
  padding: 0 9.4rem 0 4rem;
  background-color: rgba(37, 66, 114, 0.6);
  position: relative;
  li {
    font-size: 1.4rem;
    text-decoration: none;
    .MuiAutocomplete-listbox {
      background: var(--baseBlue);
      color: var(--white);
      max-height: 10rem;
      > li {
        font-size: 1.1rem;
        &:hover {
          background-color: var(--brightBlue);
        }
      }
    }
  }
  a {
    color: var(--white);
    text-decoration: none;
    padding-bottom: 1.5rem;

    &:visited {
      color: var(--white);
    }
    &.active {
      border-bottom: 3px solid white;
    }
  }
  ${Mq.sm} {
    display: ${({ isMobileOpen }) => (isMobileOpen ? 'block' : 'none')};
    position: fixed;
    top: 3rem;
    height: 100vh;
    background-color: var(--baseBlue);
    z-index: 15;
  }
  ${Mq.md} {
    li {
      font-size: 1.2rem;
      text-decoration: none;
    }
  }
`
const MobileHeader = styled.div`
  display: none;
  ${Mq.sm} {
    display: ${({ isMobileOpen }) => (isMobileOpen ? 'flex' : 'none')};
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    background-color: var(--darkBlue);
    height: 3rem;
    position: fixed;
    top: 0;
    z-index: 15;
    padding: 0 1rem;
  }
`
const MobileClose = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`

const DropDown = styled.div`
  position: absolute;
  //margin-top: 3px;
  right: ${({ right }) => (right ? 0 : 'unset')};
  height: ${({ height }) => height};
  background-color: rgba(37, 66, 114, 0.9);
  display: ${(props) => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  font-size: 1.125rem;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  z-index: 5;
  a {
    text-decoration: none;
    color: var(--white);
    text-align: left;
    &:visited {
      color: var(--white);
    }
    &:hover {
      color: var(--brightBlue);
    }
    // &:focus {
    //   outline: 2px solid red;
    // }
  }
`

const Disabled = styled.div`
  font-size: 1.2rem;
  padding-bottom: 1rem;
  opacity: 0.3;
  font-size: 1.125rem;
`
const MenuButton = styled.button`
  background-color: rgba(37, 66, 114, 0);
  border: none;
  color: white;
  padding: ${(props) =>
    props.trim ? '1.5rem .2rem calc(1.5rem - 3px)' : '1.5rem .2rem'};
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: var(--paleBlue);
  }
  &:focus {
    outline: 2px solid var(--mediumBlue);
    outline-offset: -2px;
  }
  &.active {
    border-bottom: 3px solid white;
  }
  a {
    &:hover {
      color: var(--paleBlue);
    }
    // &:focus {
    //   outline: 2px solid var(--paleBlue);
    // }
  }
`
const StyledLabel = styled.p`
  margin: 0 0 0.5rem 0;
`

const StyledTextField = styled(TextField)`
  //position: relative;
  .MuiInputBase-root {
    background-color: var(--baseBlue);
    height: 2.5rem;
    color: var(--white);
    padding-right: 0;
  }
  .MuiInputBase-input {
    color: var(--white);
    padding: 9px 4.5px;
  }
  .MuiOutlinedInput-root {
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0 9px;
    &:hover {
      border: 1px solid var(--mediumBlue);
    }
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-input {
    padding: 4.5px 4px;
  }
  .MuiIconButton-root {
    color: var(--white);
  }
`

const SectionNav = () => {
  const [menu, setMenu] = useState('')
  const { region, setRegion, setSection, isMobileOpen, setMobileOpen } =
    useContext(NavContext)
  const { originalPath, navigate } = useI18next()
  const { t } = useTranslation()

  //this works for Link
  const isActive = ({ isCurrent }) => {
    const params = originalPath.split('/')

    //UUGGGHHHHH
    //handles issue with trailing slash when navigating to new region
    let test
    if (
      params[1].startsWith('ig') ||
      params[1].startsWith('sp') ||
      !REGIONS_LIST.includes(params[1])
    ) {
      test = false
    } else if (params[2]) {
      test = false
    } else test = true

    return isCurrent || test ? { className: 'active' } : null
  }
  //this works for Button
  //"active" state has to come from path in order to still be there after page reload

  const isNot = (param, indicator) => {
    if (param) return !param.startsWith(indicator)
    else return true
  }
  const getActiveMenuItem = (menuCode) => {
    const pathArray = originalPath.split('/')

    if (
      pathArray[1]?.startsWith(menuCode) ||
      pathArray[2]?.startsWith(menuCode)
    ) {
      return 'active'
    } else if (
      menuCode === 'cnty' &&
      pathArray[1] &&
      isNot(pathArray[1], 'sp') &&
      isNot(pathArray[1], 'ig') &&
      isNot(pathArray[2], 'sp') &&
      isNot(pathArray[2], 'ig') &&
      !REGIONS_LIST.includes(pathArray[1])
    ) {
      return 'active'
    } else return ''
  }

  const getRegionFromPath = () => {
    const params = originalPath.split('/')
    if (
      originalPath === '/' ||
      params[1].startsWith('ig') ||
      params[1].startsWith('sp')
    )
      //then there is no region so
      return 'GBL'
    //else return region param
    else return params[1].toUpperCase()
  }

  const regionISO = getRegionFromPath()

  //getting list of countries for country menu from random indicator and year
  const { rows } = useStaticQuery(graphql`
    query {
      rows: allCountries(
        filter: { IA2030_IND: { eq: "IG2.1" }, YEAR: { eq: 2019 } }
      ) {
        nodes {
          COUNTRY
          COUNTRY_NAME
          WHO_REGION
          UNICEF_REGION
          WB_INCOME_STATUS
        }
      }
    }
  `)

  const countries = rows.nodes.sort((a, b) =>
    a.COUNTRY_NAME < b.COUNTRY_NAME ? -1 : 1
  )
  //this sets location for nav when click on menu parent so that menu item can go right place.
  //Only needed for when site loads at url that is not landing page.
  const handleRoute = (menuCode) => {
    setMenu(menu === menuCode ? '' : menuCode)
    setRegion(regionISO)
  }

  //this sets location for nav and active link once you've click on an li
  const handleNav = (sectionRoute, menuCode) => {
    setSection(sectionRoute)
    setMenu(menu === menuCode ? '' : menuCode)
  }
  const currSPsToShow = [
    //'1.1',
    '1.2',
    //'1.4',
    '1.6',
    '2.1',
    '2.2',
    '3.2',
    '5.1',
    '4.1',
    '6.1',
    '6.2',
    '6.3',
    '7.1'
  ]
  const matches = useMediaQuery({ query: '(max-width: 740px)' })

  const handleX = () => {
    setMobileOpen(false)
    setMenu('')
  }

  return (
    <>
      <MobileHeader isMobileOpen={isMobileOpen}>
        <MobileClose title="close mobile menu" onClick={() => handleX()}>
          <IoMdClose color={'white'} size={24} />
        </MobileClose>
      </MobileHeader>
      {matches ? (
        <SectionNavMobile menu={menu} setMenu={setMenu} countries={countries} />
      ) : (
        <NavBar>
          <li>
            <MenuButton onClick={() => handleRoute('')}>
              <Link
                to={
                  regionISO === 'GBL' || !REGIONS_LIST.includes(regionISO)
                    ? '/'
                    : `/${regionISO.toLowerCase()}`
                }
                onClick={() => setSection('')}
                getProps={isActive}
                //className={getActiveMenuItem('ig')}
              >
                {t('overview')}
              </Link>
            </MenuButton>
          </li>
          <li>
            <MenuButton
              onClick={() => handleRoute('ig')}
              className={getActiveMenuItem('ig')}
              trim={true} //fix for extra 3px that underline adds to button (vs. adding it to link in Overview)
              aria-haspopup="menu"
            >
              {t('impact goals')}
            </MenuButton>
            <DropDown
              open={menu === 'ig'}
              right={false}
              title="menu to select an impact goal"
              role="menu"
            >
              {Object.entries(IG_LABELS).map(([key, value]) => (
                <Link
                  to={
                    region === 'GBL' ||
                    !REGIONS_LIST.includes(region.toLowerCase())
                      ? `/ig${key}`
                      : `/${region.toLowerCase()}/ig${key}`
                  }
                  onClick={() => handleNav(`ig${key}`, 'ig')}
                  key={value}
                >
                  {`${key}`} {t(value)}
                </Link>
              ))}
            </DropDown>
          </li>
          <li>
            <MenuButton
              onClick={() => handleRoute('sp')}
              className={getActiveMenuItem('sp')}
              trim={true}
              aria-haspopup="menu"
            >
              {t('strategic priorities')}
            </MenuButton>
            <DropDown
              open={menu === 'sp'}
              //right={true}
              title="menu to select a strategic priority"
              role="menu"
            >
              {Object.entries(SP_LABELS).map(([key, value]) =>
                currSPsToShow.includes(key) ? (
                  <Link
                    to={
                      region === 'GBL' ||
                      !REGIONS_LIST.includes(region.toLowerCase())
                        ? `/sp${key}`
                        : `/${region.toLowerCase()}/sp${key}`
                    }
                    onClick={() => handleNav(`sp${key}`, 'sp')}
                    key={value}
                  >
                    {`${key}`} {t(value)}
                  </Link>
                ) : (
                  <Disabled key={value} aria-hidden="true">
                    {`${key}`} {t(value)}
                  </Disabled>
                )
              )}
            </DropDown>
          </li>
          <li>
            <MenuButton
              onClick={() => handleRoute('cnty')}
              className={getActiveMenuItem('cnty')}
              trim={true}
              aria-haspopup="menu"
            >
              {t('country pages')}
            </MenuButton>
            <DropDown
              open={menu === 'cnty'}
              right={true}
              title="menu to select a country"
              height={'17.2rem'}
            >
              <StyledLabel>{t('type to select a country')}</StyledLabel>
              <Autocomplete
                id="country-select"
                disablePortal
                onChange={(event, newValue) => {
                  setSection('')
                  navigate(`/country/${newValue.COUNTRY.toLowerCase()}`)
                }}
                sx={{ width: 250 }}
                options={countries}
                autoHighlight
                popupIcon={null}
                getOptionLabel={(option) => t(option.COUNTRY_NAME)}
                renderInput={(params) => (
                  <StyledTextField {...params} variant="outlined" />
                )}
              />
            </DropDown>
          </li>
        </NavBar>
      )}
    </>
  )
}

export default SectionNav
