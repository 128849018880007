import React, { useContext } from 'react'
import styled from 'styled-components'
//import { Link } from 'gatsby'
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { IG_LABELS, SP_LABELS } from '../utils/const'
// import { navigate } from 'gatsby-link'
import NavContext from './NavContext'
import { MediaQueries as Mq } from '../styles/MediaQueries'
import { MdArrowForwardIos, MdArrowBackIos } from 'react-icons/md'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

const MobileNav = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--white);
  //padding: 0 4rem 0 4rem;
  background-color: rgba(37, 66, 114, 0.7);
  position: relative;

  a {
    text-decoration: none;
    color: var(--white);
    text-align: left;
    //padding-bottom: 1rem;
    &:visited {
      color: var(--white);
    }
  }

  ${Mq.sm} {
    display: ${({ isMobileOpen }) => (isMobileOpen ? 'block' : 'none')};
    position: fixed;
    top: 3rem;
    height: 100vh;
    background-color: var(--baseBlue);
    z-index: 15;
  }
`
const StyledLi = styled.li`
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  text-decoration: none;
  border-bottom: 1px solid var(--lightGray);
  padding: 0.5rem 1rem;

  &:last-child {
    border-bottom: none;
  }
`
const SubMenu = styled.li`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  font-size: 1rem;
  font-weight: 400;
  padding: 1rem 1.5rem;
  z-index: 5;
  .MuiAutocomplete-listbox {
    background: var(--baseBlue);
    color: var(--white);
    max-height: 10rem;
    > li {
      font-size: 1.1rem;
      &:hover {
        background-color: var(--brightBlue);
      }
    }
  }
  a {
    text-decoration: none;
    color: var(--white);
    text-align: left;
    padding-bottom: 0.7rem;
    &:visited {
      color: var(--white);
    }
  }
`
const SubMenuTitle = styled.p`
  font-size: 1.4rem;
  font-weight: 700;
  padding-bottom: 0.75rem;
`
const Disabled = styled.div`
  padding-bottom: 0.7rem;
  opacity: 0.3;
  font-size: 1.125rem;
`
const MenuButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(37, 66, 114, 0);
  border: none;
  color: white;
  padding: 1.5rem 0;
  //text-align: center;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: var(--paleBlue);
  }
  // &:focus {
  //   outline: 2px solid red;
  // }
  > svg {
    margin: 0 0.5rem;
  }
`
const Box = styled.div`
  background-color: var(--darkBlue);
  width: 300px;
  height: 17.2rem;
  padding: 20px 25px 30px 25px;
`
const StyledLabel = styled.p`
  margin: 0 0 0.5rem 0;
`

const StyledTextField = styled(TextField)`
  //position: relative;
  .MuiInputBase-root {
    background-color: var(--baseBlue);
    height: 2.5rem;
    color: var(--white);

    padding-right: 0;
  }
  .MuiInputBase-input {
    color: var(--white);
    padding: 9px 4.5px;
  }
  .MuiOutlinedInput-root {
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0 9px;
    &:hover {
      border: 1px solid var(--mediumBlue);
    }
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-input {
    padding: 4.5px 4px;
  }
  .MuiIconButton-root {
    color: var(--white);
  }
`
const SectionNavMobile = ({ menu, setMenu, countries }) => {
  const { region, setRegion, setSection, isMobileOpen, setMobileOpen } =
    useContext(NavContext)
  const { originalPath, navigate } = useI18next()
  const { t } = useTranslation()
  const regionsList = ['amr', 'afr', 'emr', 'eur', 'sear', 'wpr']

  const getRegionFromPath = () => {
    const params = originalPath.split('/')
    if (
      originalPath === '/' ||
      params[1].startsWith('ig') ||
      params[1].startsWith('sp')
    )
      //then there is no region so
      return 'GBL'
    //else return region param
    else return params[1].toUpperCase()
  }

  const regionISO = getRegionFromPath()

  //this sets location for nav when click on menu parent so that menu item can go to right place.
  const handleRoute = (menuCode) => {
    setMenu(menu === menuCode ? '' : menuCode)
    setRegion(regionISO)
    if (!menuCode) setMobileOpen(false) // because Overview has been clicked
  }

  //this sets location for nav once you've click on an li
  const handleNav = (sectionRoute, menuCode) => {
    setSection(sectionRoute)
    setMenu(menu === menuCode ? '' : menuCode)
    setMobileOpen(false)
  }
  const currSPsToShow = [
    //'1.1',
    '1.2',
    //'1.4',
    '1.6',
    '2.1',
    '2.2',
    '3.2',
    '5.1',
    '4.1',
    '6.1',
    '6.2',
    '6.3',
    '7.1'
  ]

  return (
    <>
      <MobileNav isMobileOpen={isMobileOpen}>
        <StyledLi>
          <MenuButton onClick={() => handleRoute('')}>
            <Link
              to={
                regionISO === 'GBL' || !regionsList.includes(regionISO)
                  ? '/'
                  : `/${regionISO.toLowerCase()}`
              }
              onClick={() => setSection('')}
            >
              {t('overview')}
            </Link>
          </MenuButton>
        </StyledLi>
        <StyledLi>
          <MenuButton onClick={() => handleRoute('ig')}>
            <span> {t('impact goals')}</span>
            <MdArrowForwardIos />
          </MenuButton>
        </StyledLi>
        <StyledLi>
          <MenuButton onClick={() => handleRoute('sp')}>
            {/* to make French justify left */}
            <span style={{ textAlign: 'left' }}>
              {t('strategic priorities')}
            </span>
            <MdArrowForwardIos />
          </MenuButton>
        </StyledLi>
        <StyledLi>
          <MenuButton onClick={() => handleRoute('cty')}>
            <span> {t('country pages')}</span>
            <MdArrowForwardIos />
          </MenuButton>
        </StyledLi>
      </MobileNav>
      <MobileNav isMobileOpen={isMobileOpen && menu}>
        <StyledLi>
          <MenuButton onClick={() => setMenu('')}>
            <MdArrowBackIos />
            {t('main menu')}
          </MenuButton>
        </StyledLi>

        <SubMenu open={menu === 'ig'} right={false}>
          <SubMenuTitle>{t('impact goals')}</SubMenuTitle>
          {Object.entries(IG_LABELS).map(([key, value]) => (
            <Link
              to={
                region === 'GBL' || !regionsList.includes(region.toLowerCase())
                  ? `/ig${key}`
                  : `/${region.toLowerCase()}/ig${key}`
              }
              onClick={() => handleNav(`ig${key}`, 'ig')}
              key={value}
            >
              {`${key}`} {t(value)}
            </Link>
          ))}
        </SubMenu>

        <SubMenu open={menu === 'sp'} right={true}>
          <SubMenuTitle>{t('strategic priorities')}</SubMenuTitle>
          {Object.entries(SP_LABELS).map(([key, value]) =>
            currSPsToShow.includes(key) ? (
              <Link
                to={
                  region === 'GBL' ||
                  !regionsList.includes(region.toLowerCase())
                    ? `/sp${key}`
                    : `/${region.toLowerCase()}/sp${key}`
                }
                onClick={() => handleNav(`sp${key}`, 'sp')}
                key={value}
              >
                {`${key}`} {t(value)}
              </Link>
            ) : (
              <Disabled key={value}>
                {`${key}`} {t(value)}
              </Disabled>
            )
          )}
        </SubMenu>

        <SubMenu open={menu === 'cty'} right={false}>
          <SubMenuTitle>{t('country pages')}</SubMenuTitle>
          <Box>
            <StyledLabel>{t('type to select a country')}</StyledLabel>
            <Autocomplete
              id="country-select"
              disablePortal
              onChange={(event, newValue) => {
                setMobileOpen(false)
                setSection('')
                navigate(`/country/${newValue.COUNTRY.toLowerCase()}`)
              }}
              sx={{ width: 250 }}
              options={countries}
              autoHighlight
              popupIcon={null}
              getOptionLabel={(option) => t(option.COUNTRY_NAME)}
              renderInput={(params) => (
                <StyledTextField {...params} variant="outlined" />
              )}
            />
          </Box>
        </SubMenu>
      </MobileNav>
    </>
  )
}

export default SectionNavMobile
